






































































import {
  defineComponent,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import VatToggler from '~/components/VatToggler/VatToggler.vue';
import { usePageHelpers, useUiHelpers } from '~/composables';
import useMenu from '~/composables/useMenu';
import { APP_ROUTER_PATHS } from '~/constants';
import type { CategoryTree } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'MenuTab',
  components: {
    SubMenu: () => import('../../../components/SubMenu/SubMenu.vue'),
    SvgImage,
    VatToggler,
  },
  setup() {
    const {
      categories, currentCategory, topCategories, setCurrentCategory, isSubmenu,
    } = useMenu();
    const { getCatLink } = useUiHelpers();
    const { isPrinterPage } = usePageHelpers();

    const isPrinterLink = (name: string) => name === 'Printers' && isPrinterPage.value;
    const toggleCurrentCategory = (category: CategoryTree) => {
      setCurrentCategory(currentCategory.value?.uid === category.uid ? null : category);
    };

    return {
      categories,
      topCategories,
      currentCategory,
      helpPath: APP_ROUTER_PATHS.contact,
      getCatLink,
      isPrinterLink,
      isSubmenu,
      toggleCurrentCategory,
    };
  },
  data() {
    return {
      categoryShortNames: {
        'Ink Cartridges': 'Ink',
        'Toner Cartridges': 'Toner',
        'Label Tape': 'Label tape',
      },
      excludeMenu: ['Printers', 'Label Tape', 'Paper'],
      homePath: APP_ROUTER_PATHS.home,
    };
  },
});
